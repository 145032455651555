import React from "react";

const TwoColumnSectionWithImage = ({ id, isGray, children, contentOnLeft = true }) => {
  const { content, image } = children;

  const RenderColumns = () => {
    if (contentOnLeft) {
      return (
        <>
          <div className="col-md-6 order-2 order-md-1">{content}</div>
          <div className="col-md-6 order-1 order-md-2 mb-3 mb-md-0">{image}</div>
        </>
      );
    }

    return (
      <>
        <div className="col-md-6 mb-3 mb-md-0">{image}</div>
        <div className="col-md-6">{content}</div>
      </>
    );
  };

  return (
    <section id={id} className={isGray ? "bg-light" : "bg-white"}>
      <div className="container">
        <div className="row">
          <RenderColumns />
        </div>
      </div>
    </section>
  );
};

export default TwoColumnSectionWithImage;
