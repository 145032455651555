import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../../layouts/secondary-landing";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import ContactInfo from "../../../components/contact-info/contact-info";
import SEO from "../../../components/seo/seo";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import Button from "../../../components/custom-widgets/button";
import showMktoForm from "../../../helpers/showMktoForm";
import TwoColumnSectionWithImage from "../../../components/custom-widgets/two-column-section-with-image";
import BestBanksDefault from "../../../components/best-banks/best-banks-default";

const DepositAccounts = () => {
  const title = "Commercial Deposit Accounts";
  const description =
    "Discover WaFd Bank offering commercial deposit accounts. Our commercial bankers will work with you to determine the right solution for your business.";
  const imgData = useStaticQuery(graphql`
    query {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/deposit-accounts/hero-deposit-accounts-011223-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/deposit-accounts/hero-deposit-accounts-011223-XL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/deposit-accounts/hero-deposit-accounts-011223-LG.jpg"
        }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/deposit-accounts/hero-deposit-accounts-011223-MD.jpg"
        }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/deposit-accounts/hero-deposit-accounts-011223-SM.jpg"
        }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/deposit-accounts/hero-deposit-accounts-011223-XS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/deposit-accounts/hero-deposit-accounts-011223-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);
  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/commercial-banking/treasury-management-services/deposit-accounts"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-deposit-accounts-011223.jpg"
      }
    ]
  };
  const heroChevronData = {
    id: "deposit-accounts-hero",
    chevronBgClass: "bg-dark-green-01",
    ...getHeroImgVariables(imgData),
    altText: "Woman depositing check with a mobile phone.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Services to Run Your Business Efficiently"
          }
        },
        {
          id: 3,
          button: {
            id: "hero-cta-btn-1",
            class: "btn-white",
            onClick: () => showMktoForm(1067),
            type: "button",
            text: "Contact Us"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/commercial-banking",
      title: "Commercial Banking"
    },
    {
      id: 2,
      url: "/commercial-banking/treasury-management-services",
      title: "Treasury Management"
    },
    {
      id: 3,
      active: true,
      title: "Deposit Accounts"
    }
  ];

  const FeaturesContent = () => {
    return (
      <>
        <h2 className="text-success">Accounts to Fit Your Needs</h2>
        <p>
          Your checking account is more than a number. It's your connection to cash, payments, receivables and more. We
          have options for accounts with a wide range of balances and activity, from low to high. Our experienced
          Commercial Bankers will work with you to determine the right solution to fit your specific business and
          liquidity needs.
        </p>
        <Button
          id={"features-cta-contact-us"}
          class={"btn-primary no-min-width w-100 w-sm-auto"}
          onClick={() => showMktoForm(1067)}
          showIcon={false}
          type={"button"}
          text={"Contact Us"}
        />
      </>
    );
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container pb-0">
        <h1 className="mb-0">Commercial Deposit Accounts</h1>
      </section>
      <TwoColumnSectionWithImage contentOnLeft={false}>
        {{
          image: (
            <StaticImage
              src="../../../images/thumbnail-deposit-accounts-011223.jpg"
              alt="Businessman smiling and working on a laptop in the office."
              placeholder="blurred"
              className="col-md mb-3"
              quality="100"
            />
          ),
          content: <FeaturesContent />
        }}
      </TwoColumnSectionWithImage>
      <ContactInfo type="commercial" />
      <BestBanksDefault />
    </SecondaryLanding>
  );
};
export default DepositAccounts;
