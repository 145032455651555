const showMktoForm = (formId) => {
  MktoForms2.loadForm("https://app-sj21.marketo.com", "316-DXP-546", formId, function (form) {
    MktoForms2.lightbox(form).show();
    // Form is now rendered but form variable has not querySelector method, using JS to find it on the page
    const formOnDoc = document.querySelector(`#mktoForm_${formId}`); // Select the form using id
    // Focus first input
    if (formOnDoc) {
      const firstInput = formOnDoc.querySelector('input');
      firstInput.focus();
    }
  });
};

export default showMktoForm;
