import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

import Button from "../../components/custom-widgets/button";
import "./contact-info.scss";

//helper functions
import useToggleMinimizeChat from "../../hooks/use-toggle-minimize-chat";
import showMktoForm from "../../helpers/showMktoForm";
import MktoForm from "../mkto-form/mkto-form";

const descriptionText = {
  contact: {
    en: "For assistance with WaFd Bank products and services.",
    es: "Para obtener ayuda con los productos y servicios de WaFd Bank."
  },

  business: {
    en: "For more information about our business accounts and services.",
    es: "Para más información sobre nuestras cuentas y servicios de negocio."
  },

  commercial: {
    en: "For more information about our commercial accounts and services.",
    es: "Para más información sobre nuestras cuentas y servicios comerciales."
  }
};

const contactOptionsData = [
  {
    id: "phone",
    iconWhite: {
      desktop: "/images/icons/contact-info-component/icon-24-hour-desktop.svg",
      mobile: "/images/icons/contact-info-component/icon-24-hour-mobile.svg"
    },
    iconGray: {
      desktop: "/images/icons/contact-info-component/icon-24-hour-desktop-white.svg",
      mobile: "/images/icons/contact-info-component/icon-24-hour-mobile-white.svg"
    },
    title: "24-Hour Banking",
    esTitle: "Banca las 24-Horas",
    url: "tel:800-324-9375",
    esUrl: "tel:800-324-9375",
    beforeText: "Call us at",
    esBeforeText: "Llámanos al",
    text: "800-324-9375",
    esText: "800-324-9375"
  },
  {
    id: "chat",
    iconWhite: {
      desktop: "/images/icons/contact-info-component/icon-chat-desktop.svg",
      mobile: "/images/icons/contact-info-component/icon-chat-mobile.svg"
    },
    iconGray: {
      desktop: "/images/icons/contact-info-component/icon-chat-desktop.svg",
      mobile: "/images/icons/contact-info-component/icon-chat-mobile.svg"
    },
    title: "Chat",
    esTitle: "Chat",
    ComponentText: ({ isSpanish, id, toggleChat, chatLoading, textColor }) => {
      const COMPONENT_OPTIONS = {
        id: `contact-option-${id}-link`,
        type: "button",
        class: "btn-anchor-link text-underline no-min-width px-0 stretched-link" + textColor,
        containerClass: "d-flex align-items-center",
        text: isSpanish ? "Habla con Nosotros" : "Talk With Us",
        onClick: () => toggleChat(),
        disabled: chatLoading,
        showIcon: false
      };
      return (
        <>
          <div className="d-block d-md-none">
            <Button
              {...COMPONENT_OPTIONS}
              class={"btn-anchor-link text-underline no-min-width px-0 stretched-link font-weight-bold" + textColor}
            />
          </div>
          <div className="d-none d-md-block">
            <Button {...COMPONENT_OPTIONS} />
          </div>
        </>
      );
    }
  },
  {
    id: "location",
    iconWhite: {
      desktop: "/images/icons/contact-info-component/icon-location-heart-desktop.svg",
      mobile: "/images/icons/contact-info-component/icon-location-heart-mobile.svg"
    },
    iconGray: {
      desktop: "/images/icons/contact-info-component/icon-location-heart-desktop-white.svg",
      mobile: "/images/icons/contact-info-component/icon-location-heart-mobile-white.svg"
    },
    title: "Find a Branch",
    esTitle: "Encuentre una Sucursal",
    linkUrl: "/locations",
    esUrl: "/es/sucursales",
    text: "Your Local Bank",
    esText: "Tu Banco Local"
  },
  {
    id: "business",
    iconWhite: {
      desktop: "/images/icons/contact-info-component/icon-find-banker-desktop.svg",
      mobile: "/images/icons/contact-info-component/icon-find-banker-mobile.svg"
    },
    iconGray: {
      desktop: "/images/icons/contact-info-component/icon-find-banker-desktop-white.svg",
      mobile: "/images/icons/contact-info-component/icon-find-banker-mobile-white.svg"
    },
    title: "Small Business Bankers",
    esTitle: "Banqueros de Negocios Pequeños",
    linkUrl: "/business-banking/near-me",
    text: "Find a Community Banker",
    esText: "Encuentre un Banquero Comunitario"
  },
  {
    id: "commercial",
    iconWhite: {
      desktop: "/images/icons/contact-info-component/icon-find-banker-desktop.svg",
      mobile: "/images/icons/contact-info-component/icon-find-banker-mobile.svg"
    },
    iconGray: {
      desktop: "/images/icons/contact-info-component/icon-find-banker-desktop-white.svg",
      mobile: "/images/icons/contact-info-component/icon-find-banker-mobile-white.svg"
    },
    title: "Commercial Bankers",
    esTitle: "Banqueros Comerciales",
    ComponentText: ({ isSpanish, id, textColor }) => {
      const COMPONENT_OPTIONS = {
        id: `contact-option-${id}-link`,
        type: "button",
        class: "btn-anchor-link text-underline no-min-width px-0 stretched-link" + textColor,
        containerClass: "d-flex align-items-center",
        text: isSpanish ? "Encuentre un Banquero Comercial" : "Find a Commercial Banker",
        onClick: () => showMktoForm(1067),
        showIcon: false
      };
      return (
        <>
          <div className="d-block d-md-none">
            <Button
              {...COMPONENT_OPTIONS}
              class={"btn-anchor-link text-underline no-min-width px-0 stretched-link font-weight-bold" + textColor}
            />
          </div>
          <div className="d-none d-md-block">
            <Button {...COMPONENT_OPTIONS} />
          </div>
        </>
      );
    }
  }
];

const ContactOption = (props) => {
  const {
    id,
    iconWhite,
    iconGray,
    title,
    esTitle,
    url,
    esUrl,
    linkUrl,
    beforeText,
    esBeforeText,
    text,
    esText,
    isSpanish,
    isLightBackground,
    ComponentText,
    toggleChat,
    chatLoading
  } = props;
  const beforeOptionText = esBeforeText && isSpanish ? esBeforeText : beforeText;
  const optionTitle = esTitle && isSpanish ? esTitle : title;
  const optionText = esText && isSpanish ? esText : text;
  const optionUrl = esUrl && isSpanish ? esUrl : linkUrl || url;

  const optionIcon = isLightBackground ? iconWhite.desktop : iconGray.desktop;
  const optionMobileIcon = isLightBackground ? iconWhite.mobile : iconGray.mobile;

  const textColor = isLightBackground ? "" : " text-white";

  const SpanTextComponent = () => {
    const BeforeOptionText = () => <>{beforeOptionText && beforeOptionText + " "}</>;
    const UrlOptionText = () => (
      <>
        {url && (
          <a id={`contact-option-${id}-link`} className={"stretched-link hybrid-link contactLink" + textColor} href={optionUrl}>
            {optionText}
          </a>
        )}
      </>
    );
    const LinkUrlOptionText = () => (
      <>
        {linkUrl && (
          <Link id={`contact-option-${id}-link`} className={"stretched-link hybrid-link contactLink" + textColor} to={optionUrl}>
            {optionText}
          </Link>
        )}
      </>
    );

    const MobileLink = () => {
      return (
        <>
          {url && (
            <a id={`contact-option-${id}-link`} className={"stretched-link hybrid-link contactLink" + textColor} href={optionUrl}>
              <BeforeOptionText />
              {optionText}
            </a>
          )}
          {linkUrl && (
            <Link id={`contact-option-${id}-link`} className={"stretched-link hybrid-link contactLink" + textColor} to={optionUrl}>
              <BeforeOptionText />
              {optionText}
            </Link>
          )}
        </>
      );
    };
    return (
      <>
        <span className="d-none d-md-block mb-0">
          <BeforeOptionText />
          <UrlOptionText />
          <LinkUrlOptionText />
        </span>
        <span className="d-block d-md-none mb-0 font-weight-bold text-blue-60">
          <MobileLink />
        </span>
      </>
    );
  };

  return (
    <div className="contactOption">
      <div className="contactImageContainer" id={`contact-option-${id}-icon`}>
        <div className={"d-md-none"}>
          <img alt="" className="contactImage" src={optionMobileIcon} />
        </div>
        <div className={"d-none d-md-block"}>
          <img alt="" className="contactImage" src={optionIcon} />
        </div>
      </div>
      <div className={"d-block d-sm-flex d-md-column-v-center text-md-center text-gray-90 mb-0" + textColor}>
        <div className={"d-block d-md-none"}>
          <span className="font-weight-bold mb-0 optionTitle">{optionTitle}</span>
        </div>
        <div className={"d-none d-md-block"}>
          <span className="h4 mb-0 optionTitle">{optionTitle}</span>
        </div>

        {ComponentText ? (
          <ComponentText
            id={id}
            isSpanish={isSpanish}
            toggleChat={toggleChat}
            chatLoading={chatLoading}
            textColor={textColor}
          />
        ) : (
          <SpanTextComponent />
        )}
      </div>
    </div>
  );
};

// TODO: this component could be more robust, e.g. ability to pass in the "For more information..." text.

/**
 * This is the component to render the contact us section component.
 *
 * @param {Object} props - The object containing props.
 * @param {boolean} props.isSpanish - optional boolean value to show Spanish or English content.
 * @param {string} props.bgClass - optional string classes like "bg-gray-5".
 * @param {string} props.type - optional string with these accepted values "contact", "business", "commercial".
 * @param {string[]} props.options - optional array with the buttons shown in the component, accepted values ["phone", "chat", "location", "business", "commercial"] the current component can fit up to 3 options at the same time.
 * @returns {React.JSX.Element} Returns the JSX component to render.
 */
const ContactInfo = ({
  isSpanish = false,
  bgClass = null, //"bg-gray-5",
  type = "contact", // contact, business, commercial
  options = null // array = ["phone", "chat", "location"]
}) => {
  const { toggleChat, chatLoading } = useToggleMinimizeChat();

  const typeOptions = {
    contact: { bgClass: "bg-gray-5", options: ["phone", "chat", "location"] },
    business: { bgClass: "bg-gray-5", options: ["business", "phone", "location"] },
    commercial: { bgClass: "bg-info", options: ["commercial", "phone", "location"] }
  };

  const bgClassByType = bgClass ? bgClass : typeOptions[type].bgClass;

  const lightBackgrounds = ["bg-white", "bg-gray-5"];

  const isLightBackground = lightBackgrounds.find((bg) => bg === bgClassByType);

  const contactInfoText = descriptionText[type];
  const contactOptions = options
    ? options.map((id) => contactOptionsData.find((item) => item.id === id))
    : typeOptions[type].options.map((id) => contactOptionsData.find((item) => item.id === id));

  const infoTextColumns = 12 - (contactOptions.length * 2 + 2);
  const contactInfoTextContainerClass =
    "col-md-" + infoTextColumns + " " + (isLightBackground ? "text-green-60" : "text-white");

  const descriptionInfoTextClass = "mb-md-0" + (isLightBackground ? " text-gray-90" : "");

  return (
    <section id="contact-us-section" className={bgClassByType}>
      <MktoForm />
      <div className="container">
        <div className="row">
          <div className={contactInfoTextContainerClass}>
            <span className="display-5 d-block font-weight-normal mb-4">{isSpanish ? "Llámenos" : "Contact Us"}</span>
            <p className={descriptionInfoTextClass}>{contactInfoText[isSpanish ? "es" : "en"]}</p>
          </div>

          <div className="contactOptionsContainer">
            {contactOptions.map((ctOption, index) => {
              return (
                <ContactOption
                  key={index}
                  {...ctOption}
                  isSpanish={isSpanish}
                  isLightBackground={isLightBackground}
                  toggleChat={toggleChat}
                  chatLoading={chatLoading}
                />
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};
export default ContactInfo;

ContactInfo.propTypes = {
  isSpanish: PropTypes.bool,
  bgClass: PropTypes.string,
  type: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string)
};
