import React from "react";
import { Helmet } from "react-helmet";

const MktoForm = () => {
  return (
    <Helmet>
      <script src="//app-sj21.marketo.com/js/forms2/js/forms2.min.js" defer></script>
    </Helmet>
  );
};

export default MktoForm;
