import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import styles from "./best-banks.module.scss";

import Icon from "../custom-widgets/icon";

const BestBanksDefault = () => {
  // When implementing device-specific features, Google Page Speed Accessibility test requires different IDs for mobile and desktop versions.
  // To achieve this, ensure that IDs vary based on screen size by passing the screenSize property

  const BestBanksContent = (props) => (
    <>
      <h4 className="font-weight-semi-bold">Awarded America's Best Big Bank - Three Years in a Row</h4>
      <p className="font-weight-bold">
        Best Big Bank in{" "}
        <Link id={`best-banks-link-washington-${props.screenSize}`} to="/about-us/best-bank/washington-state">
          Washington
        </Link>
        ,{" "}
        <Link id={`best-banks-link-idaho-${props.screenSize}`} to="/about-us/best-bank/idaho">
          Idaho
        </Link>
        ,{" "}
        <Link id={`best-banks-link-new-mexico-${props.screenSize}`} to="/about-us/best-bank/new-mexico">
          New Mexico
        </Link>
      </p>
      <p className="mb-lg-0">
        <a
          id={`best-banks-link-newsweek-${props.screenSize}`}
          className="text-decoration-none"
          href="https://www.newsweek.com/rankings/americas-best-banks-2023/best-big-bank-2023"
          target="_blank"
          rel="noopener noreferrer"
        >
          Newsweek Best Big Banks 2023
          <Icon name="arrow-right" class="ml-1" />
        </a>
      </p>
    </>
  );

  const BestInStateBanksContent = (props) => (
    <>
      <h4 className="font-weight-semi-bold">Awarded America's Best-in-State Bank</h4>
      <p className="font-weight-bold">
        Best-in-State Bank in{" "}
        <Link id={`best-in-state-banks-link-washington-${props.screenSize}`} to="/about-us/best-bank/washington-state">
          Washington
        </Link>
        ,{" "}
        <Link id={`best-in-state-banks-link-nevada-${props.screenSize}`} to="/about-us/best-bank/nevada">
          Nevada
        </Link>
        ,{" "}
        <Link id={`best-in-state-banks-link-new-mexico-${props.screenSize}`} to="/about-us/best-bank/new-mexico">
          New Mexico
        </Link>
      </p>
      <p className="mb-lg-0">
        <a
          id={`best-in-state-banks-link-forbes-${props.screenSize}`}
          className="text-decoration-none"
          href="https://www.forbes.com/lists/best-in-state-banks/?sh=7992502e709e"
          target="_blank"
          rel="noopener noreferrer"
        >
          Forbes Best-in-State Banks 2024
          <Icon name="arrow-right" class="ml-1" />
        </a>
      </p>
    </>
  );

  const BestBanksImage = (props) => (
    <Link to="/about-us/best-bank" id={`clickable-best-banks-image-${props.screenSize}`}>
      <StaticImage
        src="../../images/newsweek-3yr-lockup-600.png"
        alt="WaFd Bank voted Best Bank by Newsweek"
        placeholder="blurred"
        quality="100"
      />
      <span className="sr-only">America's Best Bank 2021, 2022 and 2023 - Newsweek</span>
    </Link>
  );

  const BestInStateBanksImage = (props) => (
    <Link to="/about-us/best-bank" id={`clickable-best-in-state-banks-image-${props.screenSize}`}>
      <StaticImage
        src="../../images/forbes-logo-2024-with-shadow.png"
        alt="WaFd Bank voted Best-in-State Bank by Forbes."
        placeholder="blurred"
        quality="100"
      />
      <span className="sr-only">Awarded America's Best-in-State Bank</span>
    </Link>
  );

  return (
    <section id="best-banks-section" className={`adjust-padding-bottom ${styles.bestBanksBg}`}>
      <div className="container">
        <div className="row d-none d-lg-flex">
          <div className="col-lg-6 row">
            <div className="col-lg-4">
              <BestBanksImage screenSize={"d"} />
            </div>
            <div className="col-lg-8">
              <BestBanksContent screenSize={"d"} />
            </div>
          </div>
          <div className="col-lg-6 row">
            <div className="col-lg-4">
              <BestInStateBanksImage screenSize={"d"} />
            </div>
            <div className="col-lg-8">
              <BestInStateBanksContent screenSize={"d"} />
            </div>
          </div>
        </div>
        <div className="d-block d-lg-none">
          <div className="row justify-content-around mb-4">
            <div className="col-6">
              <BestBanksImage screenSize={"m"} />
            </div>
            <div className="col-6">
              <BestInStateBanksImage screenSize={"m"} />
            </div>
          </div>
          <BestBanksContent screenSize={"m"} />
          <BestInStateBanksContent screenSize={"m"} />
        </div>
      </div>
    </section>
  );
};
export default BestBanksDefault;
